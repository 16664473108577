import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../components/common/PageHeader"
import IngressPage from "../components/common/IngressPage"
import MainPageFeaturedImage from "../components/common/MainPageFeaturedImage"
import MedarbetareLong from "../components/common/MedarbetareLong"
import Seo from "../components/seo"

export default function AboutUsEn() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 44 }) {
        id
        databaseId
        title
        featuredImage {
          node {
            caption
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        language {
          slug
        }
        translations {
          uri
        }
        medarbetarinfo {
          ingress
          medarbetare {
            epost
            kortText
            langText
            namn
            telefonnummer
            titel
          }
        }
      }
    }
  `)
  const { title, medarbetarinfo, featuredImage } = data.wpPage
  const lang = data.wpPage.language.slug
  const translation = data.wpPage.translations[0]?.uri

  return (
    <Layout lang={lang} translation={translation}>
      <Seo title={data.wpPage.title} />
      <div className="space-y-8">
        <PageHeader text={title} />
        <IngressPage text={medarbetarinfo.ingress} />
        <MainPageFeaturedImage img={featuredImage} />

        <div className="p4">
          <MedarbetareLong medarbetare={medarbetarinfo.medarbetare} />
        </div>
      </div>
    </Layout>
  )
}
